<template>
  <div class="container container_estado mt-5" style="">
      <div class="ml-2 mb-4">
          <h4 class="miperfil_title">{{ bienveido }} {{nameUser}}</h4>
      </div>
  
    <!-- <div class="my-4 ">
      <b-avatar class="bg-avatar zoom" variant="transparent" size="4.5rem"><img src="../../assets/img/logo.png" alt="avatar" ></b-avatar>
        
    </div> -->

    <!-- data table -->
    <div class="container-fluid">
      <!-- <div class="searchPropiedad_container">
        <div class="searchPropiedad_content">
          <input @change="handleInputSearch" v-model="searchItem" class="input" name="searchPropiedad" :placeholder="nombre_de_propiedad">

        </div>
      </div> -->
      
      <div>
       
        <b-table class="animate__animated animate__fadeInUp mt-5" sticky-header striped outlined bordered head-variant="dark" hover :items="items_table" :fields="fields">
           <template #cell(RESERVAS)="data" >
            <button @click="handleVerReservas(data.item)" class="btn btn-dark">{{ ver_reserva_text }}</button>
           </template>
        </b-table>
      </div>
    </div>

  </div>
</template>

<script>
// import FormVue from "@/components/Form.vue";
export default {
  components: {
    // FormVue,
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  data() {
    return {
      bienveido:"",
      nombre_de_propiedad:"",
      ID_PROPIEDAD_text: "",
      NOMBRE_PROPIEDAD_text:"",
      DIRECCION_text:"",
      TELEFONO_text:"",
      PROVINCIA_text:"",
      RESERVAS_text:"",
      ver_reserva_text:"",
      img: 0,
      perPage: 25,
      currentPage: 1,
      filter: null,
      table: "users",
      singleTable: "user",
      tableSpanish: "Mi Perfil",
      items: [],
      row:{},
      user:{},
      nameUser:'',
      file1: null,
      filename1: null,
      searchItem:'',
      items_table: [
        
        ],
      fields:[
        { key:'ID_PROPIEDAD', label: "" },
        { key:'NOMBRE_PROPIEDAD', label: "" },
        { key: 'DIRECCION', label: "" },
        { key: 'TELEFONO', label: "" },
        { key: 'PROVINCIA', label:"" },
        { key:'RESERVAS', label:"" }
      ]  
    };
  },
  async mounted() {
    
    this.user = await JSON.parse(localStorage.getItem('user'))
    let name = this.user.propietaro.NOMBRE.split(' ')[0].trim()
    this.nameUser = name.replace(name.slice(1), (c) => c.toLowerCase())
    this.llenandoTablaPropiedades()
    this.llenarCampos()
    this.$store.watch(
      (state) => [state.recarga].join(),
      async () => {
        // this.showItemsNumber();
        // this.refreshButton();

         this.llenarCampos()
        //console.log(this.textos)
      }
    );
    //this.getData();
  },
  methods: {

    llenarCampos(){
      let textos = JSON.parse(localStorage.getItem("text"))

      this.bienveido = textos.clientes_title_bienvenido
      this.nombre_de_propiedad = textos.clientes_input_placeholder
      this.ID_PROPIEDAD_text = textos.clientes_table_propiedades_idpropiedad
      this.fields[0].label = this.ID_PROPIEDAD_text
      this.NOMBRE_PROPIEDAD_text = textos.clientes_table_propiedades_nomobre_propiedad
      this.fields[1].label = this.NOMBRE_PROPIEDAD_text
      this.DIRECCION_text = textos.clientes_table_propiedades_direccion
      this.fields[2].label = this.DIRECCION_text
      this.TELEFONO_text = textos.clientes_table_propiedades_telefono
      this.fields[3].label = this.TELEFONO_text
      this.PROVINCIA_text = textos.clientes_table_propiedades_provincia
      this.fields[4].label = this.PROVINCIA_text
      this.RESERVAS_text = textos.clientes_table_propiedades_reservas
      this.fields[5].label = this.RESERVAS_text
      this.ver_reserva_text = textos.cliente_table_propiedades_button_verreservas
     
      this.llenandoTablaPropiedades()
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("get", { path: 'clientes/get/' + this.user.id});
        this.row = res
        this.row.confirmaClave = this.row.clave 
      } catch (error) {
        alert(error);
      }
    },
     llenandoTablaPropiedades() {
      let propiedades = this.user.propiedades
      this.items_table = [];
      
      propiedades.map(propiedad=>{
        this.items_table.push({
          ID_PROPIEDAD:propiedad.ID_PROPIEDAD  ,
          NOMBRE_PROPIEDAD: propiedad.NOMBRE_PROPIEDAD.trim(), 
          DIRECCION: propiedad.DIRECCION.trim(), 
          TELEFONO:propiedad.TELEFONO.trim(), 
          PROVINCIA: propiedad.PROVINCIA.trim() 
          
        })
      })
    },
    handleInputSearch(e) {
      this.items_table = []
      let propiedades = this.user.propiedades.map(propiedad=>{
        return {
          ID_PROPIEDAD: propiedad.ID_PROPIEDAD,
          NOMBRE_PROPIEDAD: propiedad.NOMBRE_PROPIEDAD.trim(),
          DIRECCION: propiedad.DIRECCION.trim(),
          TELEFONO: propiedad.TELEFONO.trim(),
          PROVINCIA: propiedad.PROVINCIA.trim()
        }
      })
      
      propiedades.map(item =>{
        if(item.NOMBRE_PROPIEDAD.includes(this.searchItem.toUpperCase())){
          this.items_table.push(item)
        }
         
      })  
      if(this.items_table.length === 0 ){
        this.items_table =  [{
          ID_PROPIEDAD: '0000',
          NOMBRE_PROPIEDAD: 'NO RESULT',
          DIRECCION: 'NO RESULT',
          TELEFONO: 'NO RESULT',
          PROVINCIA: 'NO RESULT'
        }]
      }
    },
    handleVerReservas(data) {
      console.log(data)
      localStorage.setItem('propiedadActiva', JSON.stringify(data) )
      this.$router.push({ name: "LINETOR_Reservas" })
    }
  },
  watch: {
    searchItem(){
      this.handleInputSearch()
    }
  }
};
</script>

<style>
.searchPropiedad_container{
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 4px;
  margin-bottom: 12px;
  font-size: 15px;
}
.input{
  height: 34px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 15px;
  background-color: #fff;
  background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 36 36%27%3E%3Cpath fill=%27%23cdcdcd%27 d=%27M35.583 32.729L27.03 24.175a15.25 15.25 0 1 0-2.88 2.88l8.554 8.554a2.04 2.04 0 0 0 2.88-2.88z%27/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-size: 25px 25px;
  background-position: 98% 50%;


}
.miperfil_title{
  display: flex;
}
.b-table-sticky-header{
max-height: 420px;
border: 1px solid hsl(0, 0%, 0%, 0.5);
font-size: 15px;
}
.table th, .table td{
vertical-align: middle;
}
@media (max-width: 955px) {
  .container_estado{
    max-width: 100%;
  }
}
</style>